import { NavigationBarHeader } from "@components";
import { Job, SourcingCampaign } from "@typings";

export const DisplayCampaignNavigationBreadcrumb = ({ sourcingCampaign }: { sourcingCampaign: SourcingCampaign }) => {
  return (
    <>
      <NavigationBarHeader pathName={"/"} />
      <NavigationBarHeader
        pathName={`/sourcing-operations/[id]/applications`}
        redirectTo={`/$sourcing-operations/${sourcingCampaign.campaign_id}/applications`}
        tsOptions={{ textToDisplay: sourcingCampaign.name }}
      />
    </>
  );
};

export const DisplayJobNavigationBreadcrumb = ({ job }: { job: Job }) => {
  return (
    <>
      <NavigationBarHeader pathName={"/jobs"} />
      <NavigationBarHeader
        pathName={`/jobs/[id]/applications`}
        redirectTo={`/jobs/${job.id}/applications`}
        tsOptions={{ textToDisplay: `${job.title} - ${job.city}` }}
      />
    </>
  );
};
