import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { ApplicationLight } from "@typings";

export type ApplicationsStore = {
  currentCampaignID: string | null;
  currentApplicationID: string | null;
  applicationsID: string[];
  currentJobID: string | null;
  setCurrentCampaignID: (campaignID: string | null) => void;
  setCurrentApplicationID: (applicationID: string | null) => void;
  setApplicationsID: (applicationsID: string[]) => void;
  setCurrentJobID: (jobID: string | null) => void;
};

export const useApplicationsStore = create<ApplicationsStore>()(
  persist(
    (set) => ({
      applicationsID: [],
      currentApplicationID: null,
      currentCampaignID: null,
      currentJobID: null,
      setCurrentCampaignID: (campaignID) => set({ currentCampaignID: campaignID }),
      setApplicationsID: (applicationsID) => set({ applicationsID }),
      setCurrentApplicationID: (applicationID) => set({ currentApplicationID: applicationID }),
      setCurrentJobID: (jobID) => set({ currentJobID: jobID }),
    }),
    {
      name: "campaign-applications-view",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export type ApplicationsSearchStore = {
  applications: ApplicationLight[];
  searchBarFocus: boolean;
  isLoading: boolean;
  limit: number | undefined;
  setApplications: (applications: ApplicationLight[]) => void;
  setSearchBarFocus: (searchBarFocus: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setLimit: (limit: number | undefined) => void;
};

export const useApplicationsSearchStore = create<ApplicationsSearchStore>((set) => ({
  applications: [],
  searchBarFocus: false,
  isLoading: false,
  limit: 10,
  setApplications: (applications) => set({ applications: applications }),
  setSearchBarFocus: (searchBarFocus) => set({ searchBarFocus: searchBarFocus }),
  setIsLoading: (isLoading) => set({ isLoading: isLoading }),
  setLimit: (limit) => set({ limit: limit }),
}));
