import { Context } from "@opentelemetry/api";

import {
  Application,
  ApplicationSearchItem,
  ApplicationShare,
  ApplicationStatusReasonSource,
  ApplicationStatusReasons,
  ApplicationsCountPerJob,
  ApplicationsReport,
  CommentKind,
  DocumentsLogs,
  Question,
  Status,
} from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { applicationsAPI } from "./api";
import { downloadFileFromResponse } from "./utils";

interface ListApplicationsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
  job_title?: string;
  city?: string;
  job_id?: string;
  q?: string;
  limit?: number;
}
const list = async (ctx: Context, params: ListApplicationsQueryParams): Promise<Array<Application>> =>
  withSpan(ctx, "ListApplications", async () => {
    const { data } = await applicationsAPI.get<Array<Application>>("", withTraceParent({ params }));
    return data;
  });

const get = async (ctx: Context, id: string): Promise<Application> =>
  withSpan(ctx, "GetApplication", async () => {
    const { data } = await applicationsAPI.get<Application>(`/${id}`, withTraceParent());
    return data;
  });

const deleteApplication = async (ctx: Context, id: string): Promise<void> =>
  withSpan(ctx, "DeleteApplication", async () => {
    await applicationsAPI.delete(`/${id}`, withTraceParent());
    return;
  });

const listStatuses = async (ctx: Context): Promise<Array<Status>> =>
  withSpan(ctx, "ListApplicationsStatuses", async () => {
    const { data } = await applicationsAPI.get("/statuses", withTraceParent());
    return data;
  });

export interface UpdateApplicationStatusParams {
  application_id: string;
  body: {
    id: string; // status id
    is_bulk_action: boolean;
    send_sms: boolean;
  };
}
export interface UpdateApplicationStatusResponse {
  id: string;
}
const updateApplicationStatus = async (
  ctx: Context,
  params: UpdateApplicationStatusParams,
): Promise<UpdateApplicationStatusResponse> =>
  withSpan(ctx, "UpdateApplicationStatus", async () => {
    const { data } = await applicationsAPI.post(`/${params.application_id}/status`, params.body, withTraceParent());
    return data;
  });

export type ApplicationStatusReason = {
  source: ApplicationStatusReasonSource | "";
  reason: ApplicationStatusReasons[keyof ApplicationStatusReasons];
  comment: string;
};

export type CreateApplicationStatusReasonParams = {
  application_id: string;
  status_id: string;
  body: ApplicationStatusReason;
};

const createApplicationStatusReason = async (
  ctx: Context,
  { status_id, application_id, body }: CreateApplicationStatusReasonParams,
): Promise<void> =>
  withSpan(ctx, "CreateApplicationStatusReason", async () => {
    const { data } = await applicationsAPI.post(
      `/${application_id}/status/${status_id}/reason`,
      body,
      withTraceParent(),
    );
    return data;
  });

export interface GetApplicationStatusReasonParams {
  application_id: string;
  status_id: string;
}
const getApplicationStatusReason = async (
  ctx: Context,
  params: GetApplicationStatusReasonParams,
): Promise<ApplicationStatusReason> =>
  withSpan(ctx, "GetApplicationStatusReason", async () => {
    const { data } = await applicationsAPI.get(
      `/${params.application_id}/status/${params.status_id}/reason`,
      withTraceParent(),
    );
    return data;
  });

export interface CreateApplicationCommentParams {
  application_id: string;
  body: {
    content: string; // the content of the comment
    is_bulk_action: boolean;
    kind: CommentKind;
  };
}
const createComment = async (ctx: Context, params: CreateApplicationCommentParams): Promise<void> =>
  withSpan(ctx, "CreateComment", async () => {
    await applicationsAPI.post(`/${params.application_id}/comments`, params.body, withTraceParent());
  });

interface GetReportingQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const getReporting = async (ctx: Context, params: GetReportingQueryParams): Promise<ApplicationsReport> =>
  withSpan(ctx, "GetReporting", async () => {
    const { data } = await applicationsAPI.get(`/reporting`, withTraceParent({ params }));
    return data;
  });

interface ListQuestionsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const listQuestions = async (ctx: Context, params: ListQuestionsQueryParams): Promise<Array<Question>> =>
  withSpan(ctx, "ListQuestions", async () => {
    const { data } = await applicationsAPI.get("/questions", withTraceParent({ params }));
    return data;
  });

interface GetResumeQueryParams {
  application_id: string;
  lang: string;
}
const getResume = async (ctx: Context, params: GetResumeQueryParams): Promise<void> =>
  withSpan(ctx, "GetResume", async () => {
    const res = await applicationsAPI.get(
      `/${params.application_id}/resume?lang=${params.lang}`,
      withTraceParent({
        headers: {
          Accept: ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
          "Content-Type": ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
        },
        responseType: "blob",
      }),
    );

    downloadFileFromResponse(res, { defaultFilename: "resume.pdf" });
  });

export interface ShareApplicationParams {
  application_id: string;
  body: {
    emails: Array<string>;
  };
}

const shareApplication = async (ctx: Context, params: ShareApplicationParams): Promise<void> =>
  withSpan(ctx, "ShareApplication", async () => {
    await applicationsAPI.post(`/${params.application_id}/share`, params.body, withTraceParent());
  });

const listApplicationShares = async (ctx: Context, applicationID: string): Promise<Array<ApplicationShare>> =>
  withSpan(ctx, "ListApplicationShares", async () => {
    const { data } = await applicationsAPI.get(`/${applicationID}/share`, withTraceParent());
    return data;
  });

export interface RequestDocumentsParams {
  application_id: string;
  body: {
    document_type: string;
  };
}
const requestDocuments = async (ctx: Context, params: RequestDocumentsParams): Promise<void> =>
  withSpan(ctx, "RequestDocuments", async () => {
    await applicationsAPI.post(`/${params.application_id}/documents/request`, params.body, withTraceParent());
  });

const listDocuments = async (ctx: Context, applicationID: string): Promise<Array<string>> =>
  withSpan(ctx, "ListDocuments", async () => {
    const { data } = await applicationsAPI.get(`/${applicationID}/documents/`, withTraceParent());
    return data;
  });

const listDocumentsLogs = async (ctx: Context, applicationID: string): Promise<Array<DocumentsLogs>> =>
  withSpan(ctx, "ListDocumentsLogs", async () => {
    const { data } = await applicationsAPI.get(`/${applicationID}/documents/logs`, withTraceParent());
    return data;
  });

export interface CreateDocumentsLogsParams {
  application_id: string;
  body: {
    documents_type: string;
    log_type: string;
  };
}

const createDocumentsLogs = async (ctx: Context, params: CreateDocumentsLogsParams): Promise<Array<string>> =>
  withSpan(ctx, "CreateDocumentsLogs", async () => {
    const { data } = await applicationsAPI.post(
      `/${params.application_id}/documents/logs`,
      params.body,
      withTraceParent(),
    );
    return data;
  });

export interface UploadDocumentsParams {
  application_id: string;
  document_type: string;
  body: FormData;
}

const uploadDocuments = async (ctx: Context, params: UploadDocumentsParams): Promise<void> =>
  withSpan(ctx, "UploadDocuments", async () => {
    await applicationsAPI.postForm(
      `/${params.application_id}/documents/${params.document_type}`,
      params.body,
      withTraceParent(),
    );
  });

const applicationsCountPerJob = async (ctx: Context, jobID: string): Promise<ApplicationsCountPerJob> =>
  withSpan(ctx, "ApplicationsCountPerJob", async () => {
    const { data } = await applicationsAPI.get(`/${jobID}/applications_count`, withTraceParent());
    return data;
  });

export interface SearchApplicationsParams {
  q: string;
  limit?: number;
}

const searchInOrganization = async (ctx: Context, params: SearchApplicationsParams): Promise<ApplicationSearchItem[]> =>
  withSpan(ctx, "SearchApplications", async () => {
    const { data } = await applicationsAPI.get("/search", withTraceParent({ params }));
    return data;
  });

export interface DownloadDocumentParams {
  documentPath: string;
}

const downloadDocument = async (ctx: Context, params: DownloadDocumentParams): Promise<Blob> =>
  withSpan(ctx, "DownloadDocument", async () => {
    const { data } = await applicationsAPI.get(
      `/documents/${encodeURIComponent(params.documentPath)}`,
      withTraceParent({
        headers: {
          Accept: "*/*",
        },
        responseType: "blob",
      }),
    );
    return data;
  });

export const ApplicationApi = {
  get,
  list,
  deleteApplication,
  listStatuses,
  updateApplicationStatus,
  createComment,
  getReporting,
  listQuestions,
  getResume,
  createApplicationStatusReason,
  getApplicationStatusReason,
  shareApplication,
  requestDocuments,
  listDocuments,
  listDocumentsLogs,
  createDocumentsLogs,
  uploadDocuments,
  listApplicationShares,
  applicationsCountPerJob,
  searchInOrganization,
  downloadDocument,
};
