import Head from "next/head";

import { FC, useMemo } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";

import { Box } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { JobsApi, SourcingCampaignApi } from "@api";
import {
  CallInProgressAlert,
  DisplayCampaignNavigationBreadcrumb,
  DisplayJobNavigationBreadcrumb,
  Leads,
  LeadsProps,
  Spinner,
  TelnyxWrapper,
} from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { Job, SourcingCampaign } from "@typings";

import { CallBox } from "../application/application-interview-modals/call-box";

export type LeadsWrapperProps =
  | {
      sourcingCampaignID: string;
      jobID?: never;
    }
  | {
      sourcingCampaignID?: never;
      jobID: string;
    };

export const LeadsWrapper: FC<LeadsWrapperProps> = ({ sourcingCampaignID, jobID }) => {
  if (sourcingCampaignID) {
    return <CampaignLeadsWrapper sourcingCampaignID={sourcingCampaignID} />;
  }

  if (jobID) {
    return <JobLeadsWrapper jobID={jobID} />;
  }

  return <></>;
};

export const CampaignLeadsWrapper = ({ sourcingCampaignID }: { sourcingCampaignID: string }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const sourcingCampaignQuery = useQuery<SourcingCampaign, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS, sourcingCampaignID],
    queryFn: () => SourcingCampaignApi.get(context.active(), sourcingCampaignID ?? ""),
    enabled: !!sourcingCampaignID,
  });

  const leadsProps = useMemo<LeadsProps>(() => {
    if (sourcingCampaignQuery.data) {
      return { sourcingCampaign: sourcingCampaignQuery.data };
    }

    return {} as LeadsProps;
  }, [sourcingCampaignQuery.data]);

  if (sourcingCampaignQuery.data == null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  return (
    <>
      <DisplayCampaignNavigationBreadcrumb sourcingCampaign={sourcingCampaignQuery.data} />

      <Box padding="var(--space-24) 2rem 2rem 2rem">
        <Head>
          {/* set the tab text */}
          <title>{t("head.sourcingCampaign")}</title>
        </Head>

        <TelnyxWrapper>
          <CallInProgressAlert />
          <CallBox />
          <Leads {...leadsProps} />
        </TelnyxWrapper>
      </Box>
    </>
  );
};

export const JobLeadsWrapper = ({ jobID }: { jobID: string }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const jobQuery = useQuery<Job, AxiosError>({
    queryKey: [QUERY_KEYS.JOBS, jobID],
    queryFn: () => JobsApi.get(context.active(), jobID ?? ""),
    enabled: !!jobID,
  });

  const leadsProps = useMemo<LeadsProps>(() => {
    if (jobQuery.data) {
      return { job: jobQuery.data };
    }
    return {} as LeadsProps;
  }, [jobQuery.data]);

  if (jobQuery.data == null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  return (
    <>
      <DisplayJobNavigationBreadcrumb job={jobQuery.data} />

      <Box padding="var(--space-24) 2rem 2rem 2rem">
        <Head>
          {/* set the tab text */}
          <title>{t("head.job")}</title>
        </Head>

        <TelnyxWrapper>
          <CallInProgressAlert />
          <CallBox />
          <Leads {...leadsProps} />
        </TelnyxWrapper>
      </Box>
    </>
  );
};
