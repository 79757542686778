import { useMemo } from "react";

import { Context, context } from "@opentelemetry/api";

import { useQueries, useQuery } from "@tanstack/react-query";

import { ApplicationApi, JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { ApplicationSearchItem, ApplicationsCountPerJob, JobStatus } from "@typings";
import { combineQueries } from "@utils";

import { useParseApplicationsSearchList } from "./utils";

export const useApplication = (applicationID: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
    queryFn: () => ApplicationApi.get(context.active(), applicationID),
    // refetch the application every minute to ensure that the messages history is
    // up-to-date
    refetchInterval: 60 * 1000,
  });
};

export const useApplicationWithJobInfo = (applicationID: string) => {
  const application = useApplication(applicationID);

  const job = useQuery({
    queryKey: [QUERY_KEYS.JOBS, application.data?.job_id],
    queryFn: () => JobsApi.get(context.active(), application.data?.job_id ?? ""),
    enabled: !!application.data,
  });

  const applicationWithJob = useMemo(() => {
    if (!application.data || !job.data) {
      return application.data;
    }

    const { title, city, country_code, location_state, postal_code, region } = job.data;

    return {
      ...application.data,
      job_title: title,
      city,
      country_code,
      state: location_state ?? "",
      postal_code,
      region,
    };
  }, [application.data, job.data]);

  return {
    ...application,
    data: applicationWithJob,
  };
};

export interface UseSearchApplicationsInOrganizationParams {
  q: string;
  limit?: number;
}

export const useSearchApplicationsInOrganization = (
  ctx: Context,
  organizationName: string,
  params: UseSearchApplicationsInOrganizationParams,
) => {
  const applicationsQuery = useQuery<ApplicationSearchItem[], unknown>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.APPLICATIONS_SEARCH, { organizationName }],
    queryFn: () =>
      ApplicationApi.searchInOrganization(ctx, {
        q: params.q,
        limit: params.limit,
      }),
    enabled: false,
    refetchOnMount: false,
  });

  return useParseApplicationsSearchList(applicationsQuery, {
    organization_name: organizationName,
    status: [JobStatus.ACTIVE, JobStatus.INACTIVE],
  });
};

export const useApplicationsCountPerJob = (jobID?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_COUNT_PER_JOB, jobID],
    queryFn: () => ApplicationApi.applicationsCountPerJob(context.active(), jobID ?? ""),
    enabled: !!jobID,
  });

export const useApplicationsCountPerJobs = (...jobIDs: string[]) =>
  useQueries({
    queries: jobIDs.map((jobID) => ({
      queryKey: [QUERY_KEYS.APPLICATIONS_COUNT_PER_JOB, jobID],
      queryFn: () => ApplicationApi.applicationsCountPerJob(context.active(), jobID),
      enabled: !!jobID,
    })),
    combine: combineQueries<ApplicationsCountPerJob, Record<string, Omit<ApplicationsCountPerJob, "job_id">>>(
      {},
      (acc, res) => {
        acc[res.job_id] = {
          applications_count: res.applications_count,
          reports: res.reports,
        };
        return acc;
      },
    ),
  });
