import { AxiosResponse } from "axios";

import { context } from "@opentelemetry/api";

import { ColumnSort } from "@tanstack/table-core";

import { ApplicationApi } from "@api";

interface DownloadFileParams {
  defaultFilename: string;
}

export const downloadFileFromResponse = async (response: AxiosResponse, params: DownloadFileParams) => {
  let filename = params.defaultFilename;

  // Try to fetch filename from headers.
  const match: string[] = response.headers["content-disposition"]?.match(/filename="([^"]+)"/);
  if (match && match.length >= 1) {
    // The backend server uses go, which handles all strings utf8 by default. HTTP headers are, however, handled
    // as ISO-8859-1. This causes bad encoding for special characters such as accent. Decoding the string fixes
    // the issue.
    filename = decodeURIComponent(encodeURIComponent(match[1]));
  }

  // Create file link in browser's memory
  const href = URL.createObjectURL(
    new Blob([response.data] as BlobPart[], { type: response.headers["content-type"]?.toString() }),
  );

  await downloadFile(href, filename);

  URL.revokeObjectURL(href);
};

export const downloadFile = async (filePath: string, filename: string) => {
  const blob = await fetch(filePath).then((r) => r.blob());
  const url = URL.createObjectURL(blob);

  // Create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();

  // Clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
};

export const downloadS3File = async (path: string, filename: string) => {
  const s3URL = process.env.NEXT_PUBLIC_S3_URL;
  return await downloadFile(`${s3URL ?? ""}${path}`, filename);
};

export const downloadApplicationDocument = async (filePath: string, filename: string) => {
  const blob = await ApplicationApi.downloadDocument(context.active(), {
    documentPath: filePath,
  });
  const url = URL.createObjectURL(blob);
  // Create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();

  // Clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
};

export type SortingOrder = "asc" | "desc";

export const optionalTableSorting = (src: ColumnSort | undefined): SortingOrder | undefined => {
  if (src == null) {
    return undefined;
  }

  return src.desc ? "desc" : "asc";
};
