import { useCallback, useMemo } from "react";

import { uniq } from "lodash";

import { context } from "@opentelemetry/api";

import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { AuthProxyAPI, JobsApi, SearchJobsParams } from "@api";
import { QUERY_KEYS } from "@constants";
import {
  Application,
  ApplicationLight,
  type ApplicationListItem,
  ApplicationSearchItem,
  Candidate,
  CandidatesObject,
} from "@typings";

export const useParseApplicationsSearchList = (
  applicationsQuery: UseQueryResult<ApplicationSearchItem[], unknown>,
  searchJobsParams: SearchJobsParams,
) => {
  // Filter unique candidates IDs.
  const candidateIDs = useMemo<string[]>(
    () => uniq(applicationsQuery.data?.map((application) => application.candidate_id)),
    [applicationsQuery.data],
  );

  const candidatesSelect = useCallback(
    (candidates: Candidate[]): CandidatesObject =>
      candidates.reduce((acc, candidate) => {
        if (candidate.id) {
          acc[candidate.id] = candidate;
        }
        return acc;
      }, {} as CandidatesObject),
    [],
  );

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, candidateIDs],
    queryFn: () => AuthProxyAPI.searchCandidates(context.active(), candidateIDs),
    enabled: candidateIDs.length > 0,
    select: candidatesSelect,
  });

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS, searchJobsParams],
    queryFn: () => JobsApi.search(context.active(), searchJobsParams),
    select: (jobs) =>
      jobs.reduce((res, job) => {
        res[job.id] = job;
        return res;
      }, {}),
    enabled: applicationsQuery.isSuccess && (applicationsQuery.data?.length || 0) > 0,
  });

  const applications = useMemo((): ApplicationLight[] => {
    return (applicationsQuery.data ?? []).map((application) => ({
      id: application.id,
      candidate: candidatesQuery.data?.[application.candidate_id],
      job: jobsQuery.data?.[application.job_id],
    }));
  }, [applicationsQuery.data, candidatesQuery.data, jobsQuery.data]);

  return {
    applications,
    isPending: applicationsQuery.isPending || candidatesQuery.isPending || jobsQuery.isPending,
    isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading || jobsQuery.isLoading,
    isSuccess: applicationsQuery.isSuccess && candidatesQuery.isSuccess,
    refetch: applicationsQuery.refetch,
  };
};

export const useParseApplicationsList = (
  applicationsQuery: UseQueryResult<Application[], unknown>,
  searchJobsParams: SearchJobsParams,
) => {
  // Filter unique candidates IDs.
  const candidateIDs = useMemo<string[]>(
    () => uniq(applicationsQuery.data?.map((application) => application.candidate_id)),
    [applicationsQuery.data],
  );

  const candidatesSelect = useCallback(
    (candidates: Candidate[]): CandidatesObject =>
      candidates.reduce((acc, candidate) => {
        if (candidate.id) {
          acc[candidate.id] = candidate;
        }
        return acc;
      }, {} as CandidatesObject),
    [],
  );

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, candidateIDs],
    queryFn: () => AuthProxyAPI.searchCandidates(context.active(), candidateIDs),
    enabled: candidateIDs.length > 0,
    select: candidatesSelect,
  });

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS, searchJobsParams],
    queryFn: () => JobsApi.search(context.active(), searchJobsParams),
    select: (jobs) =>
      jobs.reduce((res, job) => {
        res[job.id] = job;
        return res;
      }, {}),
    enabled: applicationsQuery.isSuccess && (applicationsQuery.data?.length || 0) > 0,
  });

  const applications = useMemo((): ApplicationListItem[] => {
    return (applicationsQuery.data ?? []).map((application) => ({
      id: application.id,
      organization_name: application.organization_name,
      campaign_id: application.campaign_id,
      status: application.status,
      status_reason: application.status_reason,
      last_interaction_date: application.last_interaction_date,
      created_at: application.created_at,
      last_opened_at: application.last_opened_at,
      candidate: candidatesQuery.data?.[application.candidate_id],
      job: jobsQuery.data?.[application.job_id],
      answers: application.answers,
    }));
  }, [applicationsQuery.data, candidatesQuery.data, jobsQuery.data]);

  return {
    applications,
    isPending: applicationsQuery.isPending || candidatesQuery.isPending || jobsQuery.isPending,
    isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading || jobsQuery.isLoading,
    isSuccess: applicationsQuery.isSuccess && candidatesQuery.isSuccess,
    refetch: applicationsQuery.refetch,
  };
};
