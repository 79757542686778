import { FC, ReactNode, createContext, useCallback, useMemo, useState } from "react";

import { ApplicationsSearchResults } from "components/search/applications-search-results";

import { Stack } from "@mui/material";

import { useApplicationsSearchStore } from "@stores";

import { NavigationBar } from "./elements";

export interface NavigationPath {
  render: ReactNode;
  redirectTo?: string;
}

export interface NavigationContextType {
  paths: Record<string, NavigationPath>;
  addPath: (path: string, props: NavigationPath) => void;
  removePath: (path: string) => void;

  navigationComponent: undefined | ReactNode;
  setNavigationComponent: (node: ReactNode) => void;
  removeNavigationComponent: () => void;
}

export const NavigationContext = createContext<NavigationContextType>({
  paths: {},
  addPath: () => {},
  removePath: () => {},
  navigationComponent: undefined,
  setNavigationComponent: () => {},
  removeNavigationComponent: () => {},
});

const PATHNAME_URI_REGEX = /^((\/[A-Za-z0-9[\]_-]+)+|\/)$/;

export const WithNavigationContext: FC<{ children: ReactNode }> = ({ children }) => {
  const [paths, setPaths] = useState<Record<string, NavigationPath>>({});
  const [navigationComponent, setNavigationComponent] = useState<ReactNode>();

  const addPath = useCallback((path: string, props: NavigationPath) => {
    if (!PATHNAME_URI_REGEX.test(path)) throw new Error(`Invalid path: ${path}`);
    setPaths((prevPaths) => ({ ...prevPaths, [path]: props }));
  }, []);

  const removePath = useCallback((path: string) => {
    setPaths((prevPaths) => {
      const newPaths = { ...prevPaths };
      delete newPaths[path];
      return newPaths;
    });
  }, []);

  const removeNavigationComponent = useCallback(() => setNavigationComponent(undefined), []);

  const value = useMemo(
    () => ({ paths, addPath, removePath, navigationComponent, setNavigationComponent, removeNavigationComponent }),
    [paths, addPath, removePath, navigationComponent, setNavigationComponent, removeNavigationComponent],
  );

  const searchBarFocus = useApplicationsSearchStore((state) => state.searchBarFocus);
  const applications = useApplicationsSearchStore((state) => state.applications);
  const isLoading = useApplicationsSearchStore((state) => state.isLoading);

  return (
    <NavigationContext.Provider value={value}>
      <Stack flexDirection="column" flexGrow={1} maxHeight="100vh" overflow="auto">
        <NavigationBar />
        {searchBarFocus ? <ApplicationsSearchResults applications={applications} isLoading={isLoading} /> : children}
      </Stack>
    </NavigationContext.Provider>
  );
};
