export enum ExportHistoryStatus {
  Done = "DONE",
  Error = "ERROR",
  Started = "STARTED",
}

export interface ExportHistory {
  id: string;
  status: ExportHistoryStatus;
  user_id: string;
  created_at: Date;
}
