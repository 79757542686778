import { FC, MouseEvent, useCallback, useState } from "react";

import { useTranslation } from "next-i18next";

import {
  ArrowDropDown as ArrowDropDownIcon,
  Description as DocumentIcon,
  CloudUpload as UploadFileIcon,
} from "@mui/icons-material";
import { Box, Button, Chip, ClickAwayListener, Grid, Menu, MenuItem, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { ApplicationApi, downloadApplicationDocument } from "@api";
import { AddDocumentsAction, LoadingAnimation, RequestDocumentAction } from "@components";
import { BORDER_COLOR, BORDER_RADIUS, QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { Candidate } from "@typings";
import { buildCandidateFileName, extractFileName } from "@utils";

type ApplicationDocumentsProps = {
  applicationID: string;
  candidate: Candidate;
};

export const ApplicationDocuments: FC<ApplicationDocumentsProps> = ({ applicationID, candidate }) => {
  const { t } = useTranslation(["application-documents"]);
  loadTranslations("application-documents");

  const applicationDocumentsQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_DOCUMENTS, applicationID],
    queryFn: () => ApplicationApi.listDocuments(context.active(), applicationID),
  });

  const [currentFilePath, setCurrentFilePath] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);

  const openMenu = useCallback((event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback(() => {
    downloadApplicationDocument(
      currentFilePath,
      buildCandidateFileName(extractFileName(t, currentFilePath, applicationID, true), candidate),
    ).then(closeMenu);
  }, [applicationID, candidate, closeMenu, currentFilePath, t]);

  // shows the loading element
  if (applicationDocumentsQuery.isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }} id="application_tab_content_files">
      <Box
        display="flex"
        flexDirection="row"
        gap="1rem"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box aria-label="compatibility" role="region">
          <DocumentIcon sx={{ width: "24px", height: "24px", marginRight: "12px", float: "left" }} />
          <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
            {t("documents")}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" gap="1rem" flexWrap="wrap">
          <AddDocumentsAction
            applicationID={applicationID}
            render={({ onClick }) => (
              <Button
                sx={{ float: "right", marginLeft: "1px" }}
                variant="contained"
                onClick={onClick}
                id="upload_document"
                color="deepPurple"
                startIcon={<UploadFileIcon sx={{ width: "24px", height: "24px" }} />}
              >
                {t("add_document")}
              </Button>
            )}
          />

          <RequestDocumentAction
            applicationID={applicationID}
            render={({ onClick }) => (
              <Button
                sx={{ float: "right", marginLeft: "1px" }}
                variant="contained"
                onClick={onClick}
                id="request_document"
                color="deepPurple"
              >
                {t("request_document")}
              </Button>
            )}
          />
        </Box>
      </Box>

      <Grid container sx={{ paddingTop: "16px", gap: "8px" }}>
        {applicationDocumentsQuery.data?.map((docPath: string) => {
          return (
            <Chip
              key={docPath}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {extractFileName(t, docPath, applicationID, true)}
                  <ArrowDropDownIcon
                    sx={{ width: "24px", height: "24px", color: `${TEXT_COLOR.lowestInfo} !important` }}
                  />
                </Box>
              }
              icon={
                <DocumentIcon sx={{ width: "24px", height: "24px", color: `${TEXT_COLOR.lowestInfo} !important` }} />
              }
              onClick={(e) => {
                openMenu(e);
                setCurrentFilePath(docPath);
              }}
              sx={{
                backgroundColor: "transparent",
                borderRadius: BORDER_RADIUS[4],
                border: `1px solid ${BORDER_COLOR.inputBase}`,

                color: `${TEXT_COLOR.lowInfo}`,
              }}
            />
          );
        })}
      </Grid>

      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu} hideBackdrop={true}>
        <ClickAwayListener onClickAway={closeMenu}>
          <MenuItem onClick={handleClick}>{t("download")}</MenuItem>
        </ClickAwayListener>
      </Menu>
    </Grid>
  );
};
