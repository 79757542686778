import { TFunction } from "next-i18next";

import { Candidate } from "@typings";

// the back returns paths like: applicationID/filename.ext. This removes the applicationID in the prefix.
// It also tries to translate the filename if possible
export const extractFileName = (t: TFunction, path: string, applicationID: string, withTranslation: boolean) => {
  const filename = path.split("/").pop() ?? "";
  const filenameParts = filename?.split(".");

  return withTranslation
    ? `${t(filenameParts[0], { ns: "application-documents" })}.${filenameParts[filenameParts.length - 1]}`
    : filename;
};

export const buildCandidateFileName = (filename: string, candidate: Candidate): string => {
  return `${candidate.first_name}_${candidate.last_name}_${filename}`;
};
