import { useRouter } from "next/router";

import { FC, ReactElement, useCallback } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { ApplicationApi, downloadApplicationDocument } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { Application, Candidate } from "@typings";
import { buildCandidateFileName, extractFileName } from "@utils";

interface Props {
  application: Application;
  candidate: Candidate;
  render: ({ onClick, disabled }: { onClick: () => Promise<void>; disabled: boolean }) => ReactElement;
}

export const DownloadCandidateDocumentsAction: FC<Props> = ({ application, candidate, render }) => {
  const { t } = useTranslation(["application-details", "application-documents"]);
  loadTranslations("application-details");
  loadTranslations("application-documents");

  const { locale } = useRouter();

  const applicationDocumentsQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_DOCUMENTS, application.id],
    queryFn: () => ApplicationApi.listDocuments(context.active(), application.id),
  });

  const handleFileDownload = useCallback(
    async (filePath: string) => {
      await downloadApplicationDocument(
        filePath,
        buildCandidateFileName(extractFileName(t, filePath, application.id, true), candidate),
      );
    },
    [application.id, candidate, t],
  );

  const handleClick = useCallback(async () => {
    const promises: Promise<void>[] = [];
    try {
      applicationDocumentsQuery.data?.forEach((docPath) => {
        promises.push(handleFileDownload(docPath));
      });
      promises.push(
        ApplicationApi.getResume(context.active(), { application_id: application.id, lang: locale || "fr" }),
      );
      await Promise.all(promises);
      toast.success(t("download_success"));
    } catch (e) {
      toast.error(t("download_failed"));
    }
  }, [application.id, applicationDocumentsQuery.data, handleFileDownload, locale, t]);

  return (
    <>
      {render({
        onClick: handleClick,
        disabled: applicationDocumentsQuery.isLoading,
      })}
    </>
  );
};
